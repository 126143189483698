.HoverMain {
  position: relative;
  display: inline-block;
}
.HoverItem {
  display: none;
}
.HoverMain:hover .HoverItem {
  display: block;
}
a {
  text-decoration: none;
  color: white;
}
